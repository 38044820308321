import React, {FC} from 'react';
import './DropdownLayout.scss';
import useSwitcher from "../../../../hooks/useSwitcher";
import OutsideAlerter from "../../../common/OutsideClick/OutsideClick";
import classNames from "classnames";


interface Item {
  key: string;
  value: string;
}

interface IProps {
  listPosition?: 'top' | 'bottom'
  DropdownHeader: React.FC<{selected: string}>;
  items: Array<Item>;
  selected: string;
  onChange: (data: any, e: any) => void;
}

const DropdownLayout: FC<IProps> = (props) => {
  const {items, onChange, selected, DropdownHeader, listPosition} = props;
  const {isOpen, onClose, onToggle} = useSwitcher(false);
  const classes = classNames('drop-down', {'drop-down--opened': isOpen}, `drop-down--${listPosition}`)

  return (
    <div className={classes}>
      <OutsideAlerter onOutsideClick={onClose}>
        <div className="drop-down__active" id="drop-down__lng" onClick={onToggle}>
          <span className="drop-down__text"><DropdownHeader selected={selected}/></span>
        </div>
        <ul className="drop-down__list">
          {items.map((item: Item) => (
            <li className="drop-down__item" key={item.value} onClick={(e) => onChange(item, e)}>
              <span className="drop-down__text">{item.key}</span>
            </li>
          ))}
        </ul>
      </OutsideAlerter>
    </div>
  );
};

export default DropdownLayout;
