import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import './ToolbarMobile.scss';

import IconToolbarAccount from './components/icons/IconToolbarAccount';
import IconToolbarHome from './components/icons/IconToolbarHome';
import IconToolbarFavorite from './components/icons/IconToolbarFavorite';
import ShouldAuthModal from '../../../../screens/Hotels/common/ShouldAuthModal/ShouldAuthModal';
import { authSelector } from '../../../../store/auth/auth-selector';
import { RouteNames } from '../../../../routes';
import useScroll from '../../../../hooks/useScroll';
import { useTranslation } from 'react-i18next';
import IconToolbarLanguage from './components/icons/IconToolbarLanguage';
import { isSudtirol } from '../../../../config';
import LanguageSwitcher from '../Header/components/LanguageSwitcher/LanguageSwitcher';
import ErrorFallBack from 'react-ssr-error-boundary';

const canToolbarShow = useRouteMatch => {
   const hotelDetailRouteMatch = useRouteMatch(RouteNames.HOTELS_DETAIL);

   if (hotelDetailRouteMatch) return false;

   return true;
};

const ToolbarMobile: FC = () => {
   const { pathname } = useLocation();
   const [isOpen, setIsOpen] = useState(false);
   const { isAuthorized } = useSelector(authSelector);
   const history = useHistory();
   const hotelListRouteMatch = useRouteMatch(RouteNames.HOTELS_LIST);
   const { scrollY, scrollDirection } = useScroll();
   const [t] = useTranslation();
   const animationClass = hotelListRouteMatch && scrollY > 0 ? 'scrolled-' + scrollDirection : '';

   function clickPrivate(path) {
      if (!isAuthorized) {
         setIsOpen(true);
      } else {
         history.push(path);
      }
   }

   const showToolBar = canToolbarShow(useRouteMatch);

   return (
      showToolBar && (
         <div className={`toolbar-mobile ${animationClass}`}>
            {isOpen && <ShouldAuthModal title={t('Вам необхідно авторизуватись')} onClose={() => setIsOpen(false)} />}

            <nav>
               <ul>
                  <li className={pathname === '/' ? 'active' : ''}>
                     <Link to="/">
                        <IconToolbarHome />
                     </Link>
                  </li>
                  <li
                     className={pathname === '/profile/favorite' ? 'active' : ''}
                     onClick={() => clickPrivate('/profile/favorite')}
                  >
                     <IconToolbarFavorite />
                  </li>
                  <li className={pathname === '/profile' ? 'active' : ''} onClick={() => clickPrivate('/profile')}>
                     <IconToolbarAccount />
                  </li>
                  {isSudtirol && (
                     <li>
                        <ErrorFallBack fallBack={() => <div></div>}>
                           <LanguageSwitcher SelectedTemplate={IconToolbarLanguage} listPosition="top" />
                        </ErrorFallBack>
                     </li>
                  )}
               </ul>
            </nav>
         </div>
      )
   );
};

export default ToolbarMobile;
