import React, { FC, forwardRef, Ref, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import './Header.scss';

import LanguageSwitcher from './components/LanguageSwitcher/LanguageSwitcher';
import IconLogo from '../../../ui/icons/header/IconLogo';
import Container from '../../../ui/layouts/Container/Container';
import AuthLinks from './components/AuthLinks/AuthLinks';
import ProfileLink from './components/ProfileLink/ProfileLink';
import { profileSelector } from '../../../../store/profile/profile-selector';
import { isAuthorizedSelector } from '../../../../store/auth/auth-selector';
import HotelSearchForm from '../../../../screens/Hotels/common/HotelSearchForm/HotelSearchForm';
import useScroll from '../../../../hooks/useScroll';
import SearchButton from "../common/SearchButton/SearchButton";
// import useResizeObserver from '../../../../hooks/useResizeObserver';
// import { isClient } from '../../../../helpers/common';
import { useTranslation } from 'react-i18next';
import ErrorFallBack from 'react-ssr-error-boundary'
import { scrollToTop } from '../../../../helpers/common';
import IconSudtirolLogo from "../../../ui/icons/header/sudtirol/IconSudtirolLogo";
import { isBronui, isSudtirol } from '../../../../config';

// import ErrorBoundaries from '../../../common/ErrorBoundaries/ErrorBoundaries';

interface IProps {
    isIndexPage: boolean;
    isSearchFormOpen: boolean;
    hideSearchForm?: boolean
    isHotelsListPage?: boolean
}

const Header = forwardRef((props: IProps, ref: Ref<any>) => {
    const {isSearchFormOpen = false, isIndexPage, hideSearchForm, isHotelsListPage} = props;
    const {scrollY, scrollDirection} = useScroll();
    const scrolled = scrollY > 0;
    const [t] = useTranslation();

    const [isOpen, setOpen] = useState(isSearchFormOpen);
    const isAuthorized = useSelector(isAuthorizedSelector);
    const profile = useSelector(profileSelector);
    const {data} = profile;
    const {name, lastname, photo} = data.data;

    const openSearchPanel = () => {
        scrollToTop();
        setOpen(!isOpen);
    };

    // useEffect(() => {
    //     if(scrollDirection === 'up' && isOpen && scrollY > 100) {
    //         setOpen(false);
    //     }
    // }, [scrollDirection])

    const headerClassName = classnames('header', {
        'header--index-variant': isIndexPage && !scrolled
    });

    //const headerHeight = document.getElementsByClassName('header')?.[0].getBoundingClientRect().height || 207;

    useEffect(() => {
        if (isHotelsListPage && isOpen) {
            if (scrollY > 74) setOpen(false);
        }
    }, [scrollY])


    return (
        <header className={headerClassName} ref={ref}>
            <Container>

                <div className="header__wrapper">
                    <div>
                        <Link to="/">
                            {isBronui ? <IconLogo className="header__logo"/> : null}
                            {isSudtirol ? <IconSudtirolLogo className="header__logo"/> : null}
                        </Link>
                        {isBronui && <div className="header__logo-description">{t("Ваш помічник з планування подорожей")}</div>}
                    </div>

                    <div className="header__center">
                        {!hideSearchForm && (
                            <>
                                {!isOpen ? (
                                    <SearchButton
                                        onClick={openSearchPanel}
                                        theme={isIndexPage && !scrolled ? 'fillable' : 'outline'}
                                    />
                                ) : (
                                    <h5
                                        className={isIndexPage && !scrolled ? 'color-white' : 'color-primary'}
                                        onClick={openSearchPanel}
                                    >
                                        {t('Пошук готелів')}
                                    </h5>
                                )}
                            </>
                        )}
                    </div>

                    <div className="header__right">
                        {isSudtirol && <div className="header__language">
                            <ErrorFallBack fallBack={() => <div></div>}>
                                <LanguageSwitcher/>
                            </ErrorFallBack>
                        </div>}

                        {!isAuthorized ? (
                            <AuthLinks/>
                        ) : (
                            <ProfileLink
                                name={name}
                                lastname={lastname}
                                avatar={photo}
                            />
                        )}
                    </div>
                </div>
            </Container>

            {isOpen && <HotelSearchForm/>}
        </header>
    );
});

export default Header;
