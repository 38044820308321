
export const isBronui = process.env.RAZZLE_PROJECT_NAME === 'bronui';
export const isSudtirol = process.env.RAZZLE_PROJECT_NAME === 'sudtirol';
export const phoneMask = process.env.RAZZLE_PHONE_MASK; // isBronui ? `+38 \\(999) 999 99 99` : `+3\\9 999 999 99 99`
export const defaultCurrency = process.env.RAZZLE_DEFAULT_CURRENCY; //isBronui ?  'UAH' : 'EUR'

// export const LANGUAGE_UK = 'uk';
// export const LANGUAGE_EN = 'en';
// export const LANGUAGE_DE = 'de';
// export const LANGUAGE_IT = 'de';

export const defaultLanguage =  process.env.RAZZLE_DEFAULT_LANGUAGE;  //LANGUAGE_UK
export const allowedLanguagesArray = JSON.parse(process.env.RAZZLE_LANGUAGES).map((item) => item.value);
export const enabledLikes = false;

export const DATE_FORMAT_SERVER = 'YYYY-MM-DD';
export const DATE_FORMAT_CLIENT = 'D MMMM YYYY';
export const DATE_FORMAT_CLIENT_SHORT = 'D MMMM';

export const DATE_FORMAT_CLIENT_SHORT_DATEFNS = 'd MMMM';

export const DISABLE_STARS_SHOW = true;

// headers
export const HTTP_HEADER_CURRENCY = 'Currency'
export const HTTP_HEADER_AUTHORIZATION = 'Authorization'
export const HTTP_HEADER_LANGUAGE = 'Accept-Language'
export const HTTP_HEADER_CONTACT_TOKEN = 'Contact-Token';

export const EXTRANET_URL = process.env.RAZZLE_EXTRANET_URL; //process.env.NODE_ENV === 'development' ? 'https://extranet.dev.bronui.com' : 'https://extranet.bronui.com';
export const GOOGLE_RE_CAPTCHA_KEY = '6Le1iRclAAAAANXBWy91M1Gcd6Qw8huE52DNLzxO'


// console.log("RAZZLE_PHONE_MASK", phoneMask);
// console.log("RAZZLE_DEFAULT_CURRENCY", defaultCurrency);
// console.log("RAZZLE_DEFAULT_LANGUAGE", defaultLanguage);
// console.log("EXTRANET_URL", EXTRANET_URL);