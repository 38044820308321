import React, { FC, useCallback, useState } from "react";
import "./HotelCallbackBlock.scss";
import AppButton from "../../../../../components/ui/components/Buttons/AppButton/AppButton";
import { Form, Formik } from "formik";
import AppFormInput from "../../../../../components/ui/forms/controls/AppFormInput";
import animationData from '../../anumations/phone-animation.json';
import Lottie from "lottie-react";
import { phoneCallbackSchema } from "../../../../../helpers/validation";
import { useRouteMatch } from "react-router-dom";
import { RouteNames } from "../../../../../routes";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { postData } from "../../../../../helpers/network";
import { useSelector } from "react-redux";
import { profileSelector } from "../../../../../store/profile/profile-selector";
import FormatPhone from "../../../../../components/ui/components/FormatPhone/FormatPhone";
import { getHotelSelector } from "../../../../../store/hotel/hotel-selector";
import HotelMessage from "../HotelMessage/HotelMessage";
import { MobileOnly } from "../../../../../components/common/Media/MediaHelpers";
import PhoneFixedButton from "../PhoneFixedButton/PhoneFixedButton";
import IconClose from "../../../../../components/ui/icons/hotels/IconClose";
import { useTranslation } from "react-i18next";
import { isSudtirol, phoneMask } from "../../../../../config";
// import IconClose from "../../../../../components/ui/icons/icons/IconClose";

const IconPhone = () => {
  return (
    <svg className="icon" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.0497 6.5C15.0264 6.69057 15.924 7.16826 16.6277 7.87194C17.3314 8.57561 17.8091 9.47326 17.9997 10.45M14.0497 2.5C16.0789 2.72544 17.9713 3.63417 19.4159 5.07701C20.8606 6.51984 21.7717 8.41101 21.9997 10.44M10.2266 14.3631C9.02506 13.1615 8.07627 11.8028 7.38028 10.3532C7.32041 10.2285 7.29048 10.1662 7.26748 10.0873C7.18576 9.80695 7.24446 9.46269 7.41447 9.22526C7.46231 9.15845 7.51947 9.10129 7.63378 8.98698C7.98338 8.63737 8.15819 8.46257 8.27247 8.28679C8.70347 7.6239 8.70347 6.76932 8.27247 6.10643C8.15819 5.93065 7.98338 5.75585 7.63378 5.40624L7.43891 5.21137C6.90747 4.67993 6.64174 4.41421 6.35636 4.26987C5.7888 3.9828 5.11854 3.9828 4.55098 4.26987C4.2656 4.41421 3.99987 4.67993 3.46843 5.21137L3.3108 5.36901C2.78117 5.89863 2.51636 6.16344 2.31411 6.52348C2.08969 6.92298 1.92833 7.54347 1.9297 8.0017C1.93092 8.41464 2.01103 8.69687 2.17124 9.26131C3.03221 12.2947 4.65668 15.1571 7.04466 17.545C9.43264 19.933 12.295 21.5575 15.3284 22.4185C15.8928 22.5787 16.1751 22.6588 16.588 22.66C17.0462 22.6614 17.6667 22.5 18.0662 22.2756C18.4263 22.0733 18.6911 21.8085 19.2207 21.2789L19.3783 21.1213C19.9098 20.5898 20.1755 20.3241 20.3198 20.0387C20.6069 19.4712 20.6069 18.8009 20.3198 18.2333C20.1755 17.948 19.9098 17.6822 19.3783 17.1508L19.1835 16.9559C18.8339 16.6063 18.6591 16.4315 18.4833 16.3172C17.8204 15.8862 16.9658 15.8862 16.3029 16.3172C16.1271 16.4315 15.9523 16.6063 15.6027 16.9559C15.4884 17.0702 15.4313 17.1274 15.3644 17.1752C15.127 17.3453 14.7828 17.404 14.5024 17.3222C14.4235 17.2992 14.3612 17.2693 14.2365 17.2094C12.7869 16.5134 11.4282 15.5646 10.2266 14.3631Z"
        stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

// const IconClose = () => {
//   return (
//     <svg className="x-close" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
//       <path d="M18 6L6 18M6 6L18 18" stroke="#D1CFD5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
//     </svg>
//   )
// }

interface IProps {
  isMobile?: boolean
}

const HotelCallbackBlock:FC<IProps> = ({isMobile}) => {
  const [isCall, setCall] = useState(false);
  const [t] = useTranslation();
  const [showIndicator, setShowIndicator] = useState(false);
  const hotel = useSelector(getHotelSelector);
  const [showMessage, setShowMessage] = useState(true);
  const [isOpenPhoneModal, setIsOpenPhoneModal] = useState(isMobile && hotel.bookingState === 'phone' ? false : true);
  console.log(hotel.data.useReservations);

  const disableForSudtirol = isSudtirol && !hotel.data.useReservations;
  const closeMessage = () => {
    setShowMessage(false);
  };

  const match = useRouteMatch<{ alias: string }>(RouteNames.HOTELS_DETAIL)
  const { executeRecaptcha } = useGoogleReCaptcha();


  const profile = useSelector(profileSelector);
  const { data } = profile;
  const { phone } = data.data;

  const onSubmit = useCallback(async (values) => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    const token = await executeRecaptcha('yourAction');
    setShowIndicator(true)
    if (token) {

      if (match?.params?.alias) {
        postData(`/hotels/${match?.params?.alias}/callback`, {
          phone: values.phone,
          'g-recaptcha-response': token
        }).then(() => {
          setShowIndicator(false)
          // onSuccess(values.phone);
          setCall(true)

        })
      }
    }

  }, [executeRecaptcha, match?.params?.alias])

  return (
    <>
      <Formik initialValues={{ phone: phone }} onSubmit={onSubmit} validationSchema={phoneCallbackSchema}>
            {(formik) => {

              return (
                <Form>
                  {
                    !isCall ?
                      <>

                      {
                        isOpenPhoneModal
                        ?  <div className={`hotel-callback-block ${disableForSudtirol ? 'hotel-callback-block--disable' : ''}`}>
                            <h4>{t("Бронюйте по телефону")}

                              <MobileOnly>
                                <div className="hotel-callback-block__close" onClick={()=>setIsOpenPhoneModal(false)}><IconClose/></div>
                              </MobileOnly>
                            </h4>

                            <h6>
                              {t("Введіть Ваш номер телефону і ми зєднаємо з готелем швидко і безкоштовно")}
                            </h6>

                            <AppFormInput disabled={disableForSudtirol} alwaysShowMask={true} name="phone" label={t("Номер телефону")} unmask
                                          mask={phoneMask}/>
                            <AppButton disabled={disableForSudtirol} icon={<IconPhone/>} theme="primary" type="submit" showIndicator={showIndicator}>{t("Зєднати")}</AppButton>
                          </div>

                          : <PhoneFixedButton showMessage={showMessage} onClick={()=> setIsOpenPhoneModal(true)}/>
                      }



                        <MobileOnly>
                          {hotel.bookingState === 'phone'
                            ? <HotelMessage portal={false} closeMessage={closeMessage} showMessage={showMessage}/>
                            : null
                          }
                        </MobileOnly>
                      </>


                      :
                      <div className="hotel-callback-block">
                        <div className="hotel-callback-block__header">
                          <h4>{t("Зворотний дзвінок замовлено")}</h4>
                          <button onClick={()=> setCall(false)}>
                            <IconClose/>
                          </button>
                        </div>

                        <div className="hotel-callback-block__animation">
                          <Lottie animationData={animationData} loop={true}
                          />
                        </div>

                        <div className="hotel-callback-block__description">
                          {t("Триває зєднання")}
                          {t("Очікуйте на дзвінок менеджера готелю", {name: hotel?.data?.name})}
                        </div>

                        <div className="hotel-callback-block__phone">
                          <FormatPhone>{formik?.values?.phone}</FormatPhone>
                        </div>
                      </div>
                  }
                </Form>
              )
            }}
          </Formik>


    </>
  )
}

export default HotelCallbackBlock;