import React, { FC, memo } from 'react';
import DropdownLayout from '../../../../../ui/components/DropdownLayout/DropdownLayout';
import { useSelector } from 'react-redux';
import {
   languageSelector,
   initialSelector,

} from '../../../../../../store/initial/initial-selectors';
import { useHistory, useLocation } from "react-router-dom";

interface Data {
   key: string;
   value: number;
   selected: boolean;
}

const LanguageSwitcherSelected: FC<{ selected: string }> = props => {
   const { selected } = props;

   return <>{selected}</>;
};

const LanguageSwitcher: FC<{SelectedTemplate?: any;  listPosition?: 'top' | 'bottom'}> = memo(({SelectedTemplate, listPosition = 'bottom'}) => {
   const { language } = useSelector(initialSelector);
   const languages = JSON.parse(process.env.RAZZLE_LANGUAGES);//useSelector(languageSelector);
   const location = useLocation();

   if(!languages && !language) return null

   const changeLanguage = (data: Data) => {
      window.location.href = `/${data.value}${location.pathname}${location.search}`;
   };

   const selected = languages.find(item => item.value === language);
   const items = languages.filter(item => item.value !== language);

   return (
      <DropdownLayout
        listPosition={listPosition}
         items={items}
         selected={selected?.key}
         onChange={changeLanguage}
         DropdownHeader={SelectedTemplate || LanguageSwitcherSelected}
      />
   );
});

export default LanguageSwitcher;
